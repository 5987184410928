<!--
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-09-10 09:43:36
 * @LastEditTime: 2019-09-29 14:51:20
 * @LastEditors: Please set LastEditors
 -->
<template>
  <div class="figurefour" v-if="you_xiaoping">
        <div class="xiaoping">
            <div class="xuanze_nianfen">
                <p>
                    年份:
                    <el-select v-model="time" @change='xuanze_nianfen_biao'>
                        <el-option
                            v-for="(i,index) in year_list"
                            :key="index"
                            :label="i"
                            :value="i">
                        </el-option>
                    </el-select>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <span>单位：元</span>
                </p>
                <div v-if="time!=''">
                    <p v-for='(i,index) in chanpin_or_bumen' :key="index" @click="dianji_qiehuan_chanpin_bumen(index)">
                        <img :src="index==chanpin_bumen?require('../../assets/huang_shixin.png'):require('../../assets/ccc_quan.png')" alt="">{{i}}
                    </p>
                </div>
                <div v-if="time!=''" class="shaixuan_liebiao">
                    筛选<p class="shaixuan_box">{{shaixuan_name}}</p>
                    <ul>
                        <li v-for="(i,index) in shaixuan_list" @click="i.zhi=!i.zhi" :key="index"><img :src="i.zhi?require('../../assets/okblue.png'):require('../../assets/noccc.png')" alt=""><p>{{i.name}}</p></li>
                    </ul>
                </div>
            </div>
            <div class="table_con">
                <img v-if="time==''" src="../../assets/shili_tu4.png" style="margin-top:0.1rem;height:95%;width:100%;" alt="">
                <div v-show="chanpin_bumen==0&&time!=''" id="myCharts8" :style="{width:'100%',height:'100%',background:'#fff'}"></div>
                <ul v-if="chanpin_bumen==1&&time!=''" class="zhishi_liebiao">
                    <li>部门</li>
                    <li>编制人数</li>
                    <li>人均产值</li>
                </ul>
                <div v-if="chanpin_bumen==1&&time!=''" id="myCharts8_2" :style="{width:'8.36rem',height:'3rem',background:'#fff'}"></div>
            </div>
        </div>
        <div class="quanping" v-if="quan_zia">    <!--v-if="quan_zia==1"-->
            <div class="box">
                <div class="guanbi" @click="dianji_guanbi">X</div>
                <p class="box_text">部门销售目标(四)</p>
                <div class="box_con">
                    <div class="xuanze_nianfen">
                        <p>
                            年份:
                            <el-select v-model="time" @change='xuanze_nianfen_biao'>
                                <el-option
                                    v-for="(i,index) in year_list"
                                    :key="index"
                                    :label="i"
                                    :value="i">
                                </el-option>
                            </el-select>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <span>单位：元</span>
                        </p>
                        <div>
                            <p v-for='(i,index) in chanpin_or_bumen' :key="index" @click="dianji_qiehuan_chanpin_bumen(index)">
                                <img :src="index==chanpin_bumen?require('../../assets/huang_shixin.png'):require('../../assets/ccc_quan.png')" alt="">{{i}}
                            </p>
                        </div>
                        <div class="shaixuan_liebiao">
                            筛选<p class="shaixuan_box">{{shaixuan_name}}</p>
                            <ul>
                                <li v-for="(i,index) in shaixuan_list" @click="i.zhi=!i.zhi" :key="index"><img :src="i.zhi?require('../../assets/okblue.png'):require('../../assets/noccc.png')" alt=""><p>{{i.name}}</p></li>
                            </ul>
                        </div>
                    </div>
                    <div class="table_con">
                        <div v-show='chanpin_bumen==0' id="myCharts8_da" :style="{width:'100%',height:'100%',background:'#fff'}"></div>
                        <ul v-if="chanpin_bumen==1&&time!=''" class="zhishi_liebiao">
                            <li>部门</li>
                            <li>编制人数</li>
                            <li>人均产值</li>
                        </ul>
                        <div v-if='chanpin_bumen==1' id="myCharts8_2_da" :style="{width:'100%',height:'100%',background:'#fff'}"></div>
                    </div>
                </div>
            </div>
        </div>
  </div>
</template>

<script>
import { query_prod_list_of_as_prod_aim, generate_as_dept_aim, query_as_year_list } from '../../api/api.js'
export default {
  name: 'figurefour',
  data () {
    return {
      time: '',
      year_list: [],
      quan_zia: false,
      chanpin_or_bumen: ['产品', '部门'],
      chanpin_bumen: 0, // 切换产品和部门的按钮
      neirong_list: [],
      biaotou_list: [],
      chanpin_liebiao: [],
      bumen_liebiao: [],
      y_zhou: [],
      shuju: '', // 图中的数值
      y_zhou2: [],
      shuju2: '', // 图中的数值
      you_xiaoping: true,
      shaixuan_name: '',
      shaixuan_list: [],

      chanpin_liebiao2: [],
      bumen_liebiao2: []
    }
  },
  computed: {
  },
  props: {
    //   tu4_quanzi:String,
    //   required: true,
  },
  watch: {
    //   tu4_quanzi(val){
    //     console.log(val)
    //     this.quan_zia=val
    // },
    // quan_zia(){
    //     setTimeout(() => {
    //         this.shuju_neirong()
    //     }, 0)
    // }
    shaixuan_list: {
      handler: function (val, oldVal) {
        this.dongtai_zhanshi()
      },
      deep: true
    }
  },
  created () {
    this.jichu()
  },
  mounted () {
  },
  computed: {
  },
  methods: {
    //   基础信息
    jichu () {
      query_as_year_list({
        data: {
          ent_id: this.$ent_id(),
          aim_type: '4'
        }
      }).then(res => {
        console.log(res)
        if (res.data.code == 10237) {
          const date = JSON.parse(res.data.body.data)
          console.log(date)
          if (date != []) {
            this.year_list = []
            for (let i = 0; i < date.length; i++) {
              this.year_list.push(date[i] + '年')
            }
          }
          date.forEach(it => {
            if (it == new Date().getFullYear()) {
              this.time = new Date().getFullYear() + '年'
              this.xuanze_nianfen_biao()
            }
          })
        } else if (res.data.code == 10238) {}
      })
    },
    // 选择年份
    xuanze_nianfen_biao () {
      console.log(this.time)
      this.chanpin_bumen = '0'
      this.$emit('tu4_nianfen', this.time)
      // this.chaxun_jiekou()
      // this.chanpin_liebiao_jiekou()
      this.tuxing_shuju_jiekou()
    },
    // 图形数据接口
    tuxing_shuju_jiekou () {
      query_prod_list_of_as_prod_aim({
        data: {
          ent_id: this.$ent_id(),
          year: this.time.slice(0, 4)
        }
      }).then(res => {
        console.log(res)
        if (res.data.code == 200) {
          const date = JSON.parse(res.data.body.data)
          console.log(date)
          this.chanpin_liebiao = date
          this.chanpin_liebiao2 = date
          this.chanpin_liebiao2.map(item => {
            this.$set(item, 'zhi', true)
            this.$set(item, 'name', item.prod_name)
          })
          this.y_zhou = []
          generate_as_dept_aim({
            data: {
              ent_id: this.$ent_id(),
              year: this.time.slice(0, 4)
            }
          }).then(ras => {
            console.log(ras)
            if (ras.data.code == 200) {
              const data = JSON.parse(ras.data.body.data)
              console.log(data)
              this.bumen_liebiao = data
              this.bumen_liebiao2 = data
              this.bumen_liebiao2.forEach(item => {
                this.$set(item, 'zhi', true)
                this.$set(item, 'name', item.dept_name)
              })
              console.log(this.bumen_liebiao)
              // this.shuju_neirong()
            } else if (ras.data.code == 500) {}
          })
          if (this.chanpin_bumen == 0) {
            this.shaixuan_list = this.chanpin_liebiao2
          } else if (this.chanpin_bumen == 1) {
            this.shaixuan_list = this.bumen_liebiao2
          }
        } else if (res.data.code == 500) {}
      })
    },

    shuju_neirong () {
      if (this.chanpin_bumen == 0) {
        this.y_zhou = []
        this.shuju = ''
        for (let z = 0; z < this.chanpin_liebiao.length; z++) {
          this.y_zhou.push(this.chanpin_liebiao[z].prod_name)
        }
        const da_neirong = []
        // for(let i=0;i<this.bumen_liebiao.length;i++){
        const neirong = []
        const dept_name = ''
        const shuzi_s = []
        console.log(2222222)
        for (let b = 0; b < this.chanpin_liebiao.length; b++) {
          let s = this.chanpin_liebiao[b].amt_sale_aim

          neirong.push(Number(s).toFixed(0))

        }

        // for(let b=0;b<this.bumen_liebiao[i].list.length;b++){
        //     for(let a=0;a<this.chanpin_liebiao.length;a++){
        //         if(this.bumen_liebiao[i].list[b].prod_name==this.chanpin_liebiao[a].prod_name){
        //             neirong.push(Number(this.bumen_liebiao[i].list[b].amt_aim).toFixed(2))
        //         }else{
        //             neirong.push(0)
        //         }
        //     }
        //     dept_name=this.bumen_liebiao[i].dept_name
        // }
        da_neirong.push({
          name: dept_name,
          type: 'bar',
          barWidth: '40%',
          label: {
            show: true,
            align: 'center',
            formatter: params => {
              if (params.value != 0) {
                return Number(params.value).toFixed(0)
              } else {
                return ''
              }
            }
          },
          data: neirong.remove('0')
        })
        // }
        this.shuju = da_neirong
        if (this.you_xiaoping) {
          if (this.quan_zia) {
            setTimeout(() => {
              this.tupian3()
            }, 0)
          } else {
            setTimeout(() => {
              this.tupian()
            }, 0)
          }
        }
      } else if (this.chanpin_bumen == 1) {
        // this.y_zhou2=[]
        // this.shuju=''
        // for(let b=0;b<this.bumen_liebiao.length;b++){
        //     this.y_zhou2.push(this.bumen_liebiao[b].dept_name+'             '+this.bumen_liebiao[b].staff_num+'                '+this.$qianwei(Number(this.bumen_liebiao[b].pov).toFixed(2))+'        ')
        // }

        // console.log(this.bumen_liebiao)
        // for(let c=0;c<this.bumen_liebiao.length;c++){
        //     // let da_neirong=[]
        //     // let dept_name=''
        //     //     console.log(2222222)
        //     for(let b=0;b<this.bumen_liebiao[c].list.length;b++){
        //         console.log(this.bumen_liebiao[c].list[b])
        //         // let neirong=[]
        //         // // for(let a=0;a<this.chanpin_liebiao.length;a++){
        //         // //     if(this.bumen_liebiao[i].list[b].prod_name==this.chanpin_liebiao[a].prod_name){
        //         //         neirong.push(Number(this.bumen_liebiao[i].list[b].amt_aim).toFixed(2))
        //         //         dept_name=this.bumen_liebiao[i].list[b].prod_name
        //         //         console.log(dept_name)
        //         //         console.log(Number(this.bumen_liebiao[i].list[b].amt_aim).toFixed(2))
        //         //     // }else{
        //         //     //     neirong.push(0)
        //         //     // }
        //         // // }
        //         // console.log(neirong)
        //         // da_neirong.push({
        //         //     name:this.bumen_liebiao[c].list[b].prod_name,
        //         //     type:'bar',
        //         //     stack:'fen',
        //         //     barWidth:'40%',
        //         //     label:{
        //         //         show:true,
        //         //         align:'center',
        //         //         formatter:params=>{
        //         //             if(params.value!=0){
        //         //                 console.log(params)
        //         //                 return params.value
        //         //             }else{
        //         //                 return ''
        //         //             }
        //         //         }
        //         //     },
        //         //     // data:this.bumen_liebiao[c].list.map(item=>item.amt_aim)
        //         // })
        //     }
        //     // this.shuju2=da_neirong

        //     // da_neirong.push({
        //     //     name:this.bumen_liebiao[c].list.map(item=>item.prod_name),
        //     //     type:'bar',
        //     //     stack:'fen',
        //     //     barWidth:'40%',
        //     //     label:{
        //     //         show:true,
        //     //         align:'center',
        //     //         formatter:params=>{
        //     //             if(params.value!=0){
        //     //                 return params.value
        //     //             }else{
        //     //                 return ''
        //     //             }
        //     //         }
        //     //     },
        //     //     data:this.bumen_liebiao[c].list.map(item=>item.amt_aim)
        //     // })
        // }
        //     console.log(neirong)

        const list = []
        for (let i = 0; i < this.shaixuan_list.length; i++) {
          if (this.shaixuan_list[i].zhi) {
            list.push(this.shaixuan_list[i])
          }
        }
        this.y_zhou2 = []
        this.shuju = ''
        for (let b = 0; b < list.length; b++) {
          this.y_zhou2.push(list[b].dept_name + '             ' + list[b].staff_num + '                ' + this.$qianwei(Number(list[b].pov).toFixed(2)) + '        ')
        }

        console.log(this.chanpin_liebiao)
        const da_neirong = []
        for (let c = 0; c < this.chanpin_liebiao2.length; c++) {
          const value = []
          for (let d = 0; d < list.length; d++) {
            const a = []
            for (let e = 0; e < list[d].list.length; e++) {
              if (list[d].list[e].prod_name == this.chanpin_liebiao2[c].prod_name) {
                a.push('111')
                value.push(Number(list[d].list[e].amt_aim).toFixed(0))
              }
            }
            if (a.length == 0) {
              value.push('0')
            }
          }

          console.log(value)
          da_neirong.push({
            name: this.chanpin_liebiao2[c].prod_name,
            type: 'bar',
            stack: 'fen',
            barWidth: '40%',
            label: {
              show: true,
              align: 'center',
              formatter: params => {
                if (params.value != 0) {
                  return params.value
                } else {
                  return ''
                }
              }
            },
            data: value
          })
        }
        this.shuju2 = da_neirong
        if (this.you_xiaoping) {
          if (this.quan_zia) {
            setTimeout(() => {
              this.tupian4()
            }, 0)
          } else {
            setTimeout(() => {
              this.tupian2()
            }, 0)
          }
        }
      }
    },
    tupian () {
      const echarts = require('echarts')
      const myChart = echarts.init(document.getElementById('myCharts8'))

      myChart.setOption({
        tooltip: {
          trigger: 'axis',
          axisPointer: { // 坐标轴指示器，坐标轴触发有效
            type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
          }
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: {
          type: 'value',
          axisTick: {
            show: false
          },
          boundaryGap: false,
          axisLine: {
            show: false
          },
          axisLabel: {
            color: '#A0A4AA',
            fontSize: 10
          }
        },
        yAxis: {
          type: 'category',
          data: this.y_zhou,
          axisTick: {
            show: false
          },
          axisLine: {
            lineStyle: {
              color: '#BDC8D3'
            }
          },
          axisLabel: {
            color: '#4C4A4D',
            fontSize: 12
          }
        },
        series: this.shuju
      })
      this.autoHeight = this.y_zhou.length * 60
      myChart.getDom().style.height = this.autoHeight + 'px'

      myChart.getDom().childNodes[0].style.height = this.autoHeight + 'px'

      myChart.getDom().childNodes[0].childNodes[0].setAttribute('height', this.autoHeight)

      myChart.getDom().childNodes[0].childNodes[0].style.height = this.autoHeight + 'px'

      myChart.resize()
    },
    tupian2 () {
      const echarts = require('echarts')
      const myChart = echarts.init(document.getElementById('myCharts8_2'))
      myChart.setOption({
        tooltip: {
          trigger: 'axis',
          axisPointer: { // 坐标轴指示器，坐标轴触发有效
            type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
          },
          position: ['50%', '50%']
        },
        grid: {
          left: '6%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: {
          type: 'value',
          axisTick: {
            show: false
          },
          boundaryGap: false,
          axisLine: {
            show: false
          },
          axisLabel: {
            color: '#A0A4AA',
            fontSize: 10
          }
        },
        yAxis: {
          type: 'category',
          data: this.y_zhou2,
          axisTick: {
            show: false
          },
          axisLine: {
            lineStyle: {
              color: '#BDC8D3'
            }
          },
          axisLabel: {
            color: '#4C4A4D',
            fontSize: 12
          }
        },
        series: this.shuju2
      })
      this.autoHeight = this.y_zhou2.length * 80
      myChart.getDom().style.height = this.autoHeight + 'px'

      myChart.getDom().childNodes[0].style.height = this.autoHeight + 'px'

      myChart.getDom().childNodes[0].childNodes[0].setAttribute('height', this.autoHeight)

      myChart.getDom().childNodes[0].childNodes[0].style.height = this.autoHeight + 'px'

      myChart.resize()
    },
    tupian3 () {
      const echarts = require('echarts')
      const myChart = echarts.init(document.getElementById('myCharts8_da'))

      myChart.setOption({
        tooltip: {
          trigger: 'axis',
          axisPointer: { // 坐标轴指示器，坐标轴触发有效
            type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
          }
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: {
          type: 'value',
          axisTick: {
            show: false
          },
          boundaryGap: false,
          axisLine: {
            show: false
          },
          axisLabel: {
            color: '#A0A4AA',
            fontSize: 10
          }
        },
        yAxis: {
          type: 'category',
          data: this.y_zhou,
          axisTick: {
            show: false
          },
          axisLine: {
            lineStyle: {
              color: '#BDC8D3'
            }
          },
          axisLabel: {
            color: '#4C4A4D',
            fontSize: 12
          }
        },
        series: this.shuju
      })
      this.autoHeight = this.y_zhou.length * 60
      myChart.getDom().style.height = this.autoHeight + 'px'

      myChart.getDom().childNodes[0].style.height = this.autoHeight + 'px'

      myChart.getDom().childNodes[0].childNodes[0].setAttribute('height', this.autoHeight)

      myChart.getDom().childNodes[0].childNodes[0].style.height = this.autoHeight + 'px'

      myChart.resize()
    },
    tupian4 () {
      const echarts = require('echarts')
      const myChart = echarts.init(document.getElementById('myCharts8_2_da'))
      myChart.setOption({
        tooltip: {
          trigger: 'axis',
          axisPointer: { // 坐标轴指示器，坐标轴触发有效
            type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
          },
          position: ['50%', '50%']
        },
        grid: {
          left: '6%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: {
          type: 'value',
          axisTick: {
            show: false
          },
          boundaryGap: false,
          axisLine: {
            show: false
          },
          axisLabel: {
            color: '#A0A4AA',
            fontSize: 10
          }
        },
        yAxis: {
          type: 'category',
          data: this.y_zhou2,
          axisTick: {
            show: false
          },
          axisLine: {
            lineStyle: {
              color: '#BDC8D3'
            }
          },
          axisLabel: {
            color: '#4C4A4D',
            fontSize: 12
          }
        },
        series: this.shuju2
      })
      this.autoHeight = this.y_zhou2.length * 80
      myChart.getDom().style.height = this.autoHeight + 'px'

      myChart.getDom().childNodes[0].style.height = this.autoHeight + 'px'

      myChart.getDom().childNodes[0].childNodes[0].setAttribute('height', this.autoHeight)

      myChart.getDom().childNodes[0].childNodes[0].style.height = this.autoHeight + 'px'

      myChart.resize()
    },
    // 计算
    jisuan () {
    },
    // 点击关闭
    dianji_guanbi () {
      // this.$router.push('/aspreackeven')
      // this.quan_zia=''
      // this.$emit('nianfen_fu', '1')
      // sessionStorage.setItem("pc_mlbb_mubiao_biao", '-1');
      if (this.biaoge_nei) {
        this.quan_zia = false
        this.you_xiaoping = false
      } else {
        this.quan_zia = false
      }
      this.chanpin_bumen = '0'
      // this.yierji_panduan2_da=true
    },
    biaoge_dakai () {
      this.you_xiaoping = false
    },
    dakai_quanping (data) {
      console.log(data)
      this.you_xiaoping = true
      this.time = data.time
      this.quan_zia = data.chutu
      this.biaoge_nei = data.biaoge_nei
      this.chanpin_bumen = '0'
      this.tuxing_shuju_jiekou()
      // if(data.chutu){
      // this.nianfen_zhi()
      // this.nianfen_zhi2()
      // }else{
      //     // this.nianfen_zhi2()
      // }
    },
    // 点击切换产品和部门
    dianji_qiehuan_chanpin_bumen (index) {
      // if(this.bumen_liebiao.length!=0&&this.chanpin_liebiao.length!=0){
      this.chanpin_bumen = index
      // this.shuju_neirong()
      if (this.chanpin_bumen == 0) {
        this.shaixuan_list = this.chanpin_liebiao2
      } else if (this.chanpin_bumen == 1) {
        this.shaixuan_list = this.bumen_liebiao2
      }
      // }else{
      //     this.$message({
      //         message: '请选择年份',
      //         type: 'warning'
      //     });
      // }
    },
    dongtai_zhanshi () {
      if (this.chanpin_bumen == 0) {
        const list = []
        for (let i = 0; i < this.shaixuan_list.length; i++) {
          if (this.shaixuan_list[i].zhi) {
            list.push(this.shaixuan_list[i])
          }
        }
        this.y_zhou = []
        this.shuju = ''
        for (let z = 0; z < list.length; z++) {
          this.y_zhou.push(list[z].prod_name)
        }
        const da_neirong = []
        // for(let i=0;i<this.bumen_liebiao.length;i++){
        const neirong = []
        const dept_name = ''
        console.log(2222222)
        for (let b = 0; b < list.length; b++) {
          neirong.push(Number(list[b].amt_sale_aim).toFixed(0))
        }
        // for(let b=0;b<this.bumen_liebiao[i].list.length;b++){
        //     for(let a=0;a<this.chanpin_liebiao.length;a++){
        //         if(this.bumen_liebiao[i].list[b].prod_name==this.chanpin_liebiao[a].prod_name){
        //             neirong.push(Number(this.bumen_liebiao[i].list[b].amt_aim).toFixed(2))
        //         }else{
        //             neirong.push(0)
        //         }
        //     }
        //     dept_name=this.bumen_liebiao[i].dept_name
        // }
        da_neirong.push({
          name: dept_name,
          type: 'bar',
          barWidth: '40%',
          label: {
            show: true,
            align: 'center',
            formatter: params => {
              if (params.value != 0) {
                return params.value
              } else {
                return ''
              }
            }
          },
          data: neirong
        })
        // }
        this.shuju = da_neirong
        if (this.you_xiaoping) {
          if (this.quan_zia) {
            setTimeout(() => {
              this.tupian3()
            }, 0)
          } else {
            setTimeout(() => {
              this.tupian()
            }, 0)
          }
        }
      } else if (this.chanpin_bumen == 1) {
        const list = []
        for (let i = 0; i < this.shaixuan_list.length; i++) {
          if (this.shaixuan_list[i].zhi) {
            list.unshift(this.shaixuan_list[i])
          }
        }
        this.y_zhou2 = []
        this.shuju = ''
        for (let b = 0; b < list.length; b++) {
          this.y_zhou2.push(list[b].dept_name + '             ' + list[b].staff_num + '                ' + this.$qianwei(Number(list[b].pov).toFixed(2)) + '        ')
        }

        const da_neirong = []
        for (let c = 0; c < this.chanpin_liebiao2.length; c++) {
          const value = []
          const noo =  []
          for (let d = 0; d < list.length; d++) {
            const a = []
            for (let e = 0; e < list[d].list.length; e++) {
              if (list[d].list[e].prod_name == this.chanpin_liebiao2[c].prod_name) {
                a.push('111')
                value.push(Number(list[d].list[e].amt_aim).toFixed(0))
                // console.log(this.chanpin_liebiao2[c].prod_name)
                // console.log(list[d].list[e].prod_name )
                // console.log(list[d].list[e].amt_aim,'fffffffffff')
              }
            }
            if (a.length == 0) {
              value.push('0')
            }
          }
          da_neirong.push({
            name: this.chanpin_liebiao2[c].prod_name,
            type: 'bar',
            stack: 'fen',
            barWidth: '40%',
            label: {
              show: true,
              align: 'center',
              formatter: params => {
                if (params.value != 0) {
                  return params.value
                } else {
                  return ''
                }
              }
            },
            data: value
          })
        }
        this.shuju2 = da_neirong
        // for(let c=0;c<list.length;c++){

        //     // let dept_name=''
        //     //     console.log(2222222)
        //     // for(let b=0;b<this.bumen_liebiao[i].list.length;b++){
        //     //     let neirong=[]
        //     //     // for(let a=0;a<this.chanpin_liebiao.length;a++){
        //     //     //     if(this.bumen_liebiao[i].list[b].prod_name==this.chanpin_liebiao[a].prod_name){
        //     //             neirong.push(Number(this.bumen_liebiao[i].list[b].amt_aim).toFixed(2))
        //     //             dept_name=this.bumen_liebiao[i].list[b].prod_name
        //     //             console.log(dept_name)
        //     //             console.log(Number(this.bumen_liebiao[i].list[b].amt_aim).toFixed(2))
        //     //         // }else{
        //     //         //     neirong.push(0)
        //     //         // }
        //     //     // }
        //     //     console.log(neirong)
        //     // }

        //     da_neirong.push({
        //         name:list[c].list.map(item=>item.prod_name),
        //         type:'bar',
        //         stack:'fen',
        //         barWidth:'40%',
        //         label:{
        //             show:true,
        //             align:'center',
        //             formatter:params=>{
        //                 if(params.value!=0){
        //                     return params.value
        //                 }else{
        //                     return ''
        //                 }
        //             }
        //         },
        //         data:list[c].list.map(item=>item.amt_aim)
        //     })
        // }
        //     console.log(neirong)

        if (this.quan_zia) {
          setTimeout(() => {
            this.tupian4()
          }, 0)
        } else {
          setTimeout(() => {
            this.tupian2()
          }, 0)
        }
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.figurefour {
    height:100%;
    width:100%;
    // position: relative;
    .xiaoping{
        height:100%;
        width:100%;
        .xuanze_nianfen{
            padding-top:0.18rem;
            margin-left: 0.16rem;
            margin-right: 0.16rem;
            display: flex;
            justify-content: space-between;
            align-items: center;
            p{
                display: flex;
                align-items: center;
                &:nth-child(1){
                    font-size: 0.12rem;
                    color:#4C4A4D;
                }
                &:nth-child(2){
                    font-size: 0.12rem;
                    color:#4C4A4D;
                }
            }
            div{
                display: flex;
                align-items: center;
                p{
                    display: flex;
                    align-items: center;
                    img{
                        height:0.19rem;
                        width:0.19rem;
                        margin: 0 0.07rem 0 0.29rem;
                    }
                    font-size: 0.12rem;
                    color: #4C4A4D;
                    cursor: pointer;
                    &:nth-child(1){
                        img{
                            margin: 0 0.07rem 0 0;
                        }
                    }
                }
            }
            .shaixuan_liebiao{
                position: relative;
                .shaixuan_box{
                    width:0.8rem;
                    height:0.3rem;
                    padding: 0 0.1rem;
                    text-align: center;
                    border:0.01rem solid #ccc;
                    border-radius: 0.03rem;
                    text-overflow: ellipsis;  /* ellipsis:显示省略符号来代表被修剪的文本  string:使用给定的字符串来代表被修剪的文本*/
                    white-space: nowrap;   /* nowrap:规定段落中的文本不进行换行   */
                    overflow: hidden; /*超出部分隐藏*/
                    margin-left: 0.1rem;
                }
                &:hover{
                    ul{
                        display: block;
                    }
                }
                ul{
                    display: none;
                    position: absolute;
                    top:0.3rem;
                    width: 1.34rem;
                    height:2.09rem;
                    z-index: 999;
                    overflow-y:auto;
                    border:0.01rem solid #ccc;
                    background: #fff;
                    &::-webkit-scrollbar {
                        width: 0.2rem;
                        height: 0.08rem;
                    }

                    &::-webkit-scrollbar-thumb {
                        border-radius: 0.12rem;
                        border: 0.06rem solid rgba(0, 0, 0, 0);
                        box-shadow: 0.08rem 0 0 #A5ADB7 inset;
                    }

                    &::-webkit-scrollbar-thumb:hover {
                        box-shadow: 0.08rem 0 0 #4A4A4A inset;
                    }
                    li{
                        padding: 0.05rem;
                        margin: 0 0.16rem;
                        display: flex;
                        align-items: center;
                        cursor: pointer;
                        &:hover{
                            background: rgb(173, 173, 173);
                        }
                        img{
                            height:0.16rem;
                            width:0.16rem;
                            margin-right: 0.03rem;
                        }
                        p{
                            font-size: 0.12rem;
                            color:#4C4A4D;
                            width: 0.9rem;
                            text-overflow: ellipsis;  /* ellipsis:显示省略符号来代表被修剪的文本  string:使用给定的字符串来代表被修剪的文本*/
                            white-space: nowrap;   /* nowrap:规定段落中的文本不进行换行   */
                            overflow: hidden; /*超出部分隐藏*/
                        }
                    }
                }
            }
        }
        .table_con{
            width:95%;
            height:3rem;
            margin: 0 auto;
            margin-top: 0.11rem;
            position: relative;
            overflow-y:auto;
            &::-webkit-scrollbar {
                width: 0.2rem;
                height: 0.08rem;
            }

            &::-webkit-scrollbar-thumb {
                border-radius: 0.12rem;
                border: 0.06rem solid rgba(0, 0, 0, 0);
                box-shadow: 0.08rem 0 0 #A5ADB7 inset;
            }

            &::-webkit-scrollbar-thumb:hover {
                box-shadow: 0.08rem 0 0 #4A4A4A inset;
            }
            .zhishi_liebiao{
                display: flex;
                align-items: center;
                position: absolute;
                top:0.4rem;
                left:0.48rem;
                z-index: 2;
                li{
                    font-size: 0.12rem;
                    color:#4C4A4D;
                    margin: 0 0.29rem;
                }
            }
        }
    }
    .quanping{
        height:100%;
        width:100%;
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        overflow: auto;
        margin: 0;
        z-index:999;
        background: rgba(0,0,0,0.8);
        .box{
            position: relative;
            height:90%;
            width: 14rem;
            left:50%;
            top:5%;
            margin-left:-8rem;
            background: #F1F3F9;
            .box_text{
                position: absolute;
                font-size: 0.17rem;
                color:#4C4A4D;
                left:5%;
                top:3%;
            }
            .guanbi{
                position: absolute;
                top:-0.2rem;
                right:-0.2rem;
                height:0.6rem;
                width: 0.6rem;
                border-radius: 50%;
                background: #fc6a6a;
                color:#fff;
                text-align: center;
                font-size: 0.3rem;
                line-height: 0.6rem;
                box-shadow:0 0 0.05rem 0.05rem rgba(27, 27, 27, 0.5);
                cursor: pointer;
            }
            .box_con{
                position: relative;
                height:86%;
                width: 90%;
                left:5%;
                top:7%;
                background: #fff;
                .xuanze_nianfen{
                    padding-top:0.18rem;
                    margin-left: 0.16rem;
                    margin-right: 0.16rem;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    p{
                        display: flex;
                        align-items: center;
                        &:nth-child(1){
                            font-size: 0.12rem;
                            color:#4C4A4D;
                        }
                        &:nth-child(2){
                            font-size: 0.12rem;
                            color:#4C4A4D;
                        }
                    }
                    div{
                        display: flex;
                        align-items: center;
                        p{
                            display: flex;
                            align-items: center;
                            img{
                                height:0.19rem;
                                width:0.19rem;
                                margin: 0 0.07rem 0 0.29rem;
                            }
                            font-size: 0.12rem;
                            color: #4C4A4D;
                            cursor: pointer;
                            &:nth-child(1){
                                img{
                                    margin: 0 0.07rem 0 0;
                                }
                            }
                        }
                    }
                    .shaixuan_liebiao{
                        position: relative;
                        .shaixuan_box{
                            width:0.8rem;
                            height:0.3rem;
                            padding: 0 0.1rem;
                            text-align: center;
                            border:0.01rem solid #ccc;
                            border-radius: 0.03rem;
                            text-overflow: ellipsis;  /* ellipsis:显示省略符号来代表被修剪的文本  string:使用给定的字符串来代表被修剪的文本*/
                            white-space: nowrap;   /* nowrap:规定段落中的文本不进行换行   */
                            overflow: hidden; /*超出部分隐藏*/
                            margin-left: 0.1rem;
                        }
                        &:hover{
                            ul{
                                display: block;
                            }
                        }
                        ul{
                            display: none;
                            position: absolute;
                            top:0.3rem;
                            width: 1.34rem;
                            height:2.09rem;
                            z-index: 999;
                            overflow-y:auto;
                            border:0.01rem solid #ccc;
                            background: #fff;
                            &::-webkit-scrollbar {
                                width: 0.2rem;
                                height: 0.08rem;
                            }

                            &::-webkit-scrollbar-thumb {
                                border-radius: 0.12rem;
                                border: 0.06rem solid rgba(0, 0, 0, 0);
                                box-shadow: 0.08rem 0 0 #A5ADB7 inset;
                            }

                            &::-webkit-scrollbar-thumb:hover {
                                box-shadow: 0.08rem 0 0 #4A4A4A inset;
                            }
                            li{
                                padding: 0.05rem;
                                margin: 0 0.16rem;
                                display: flex;
                                align-items: center;
                                cursor: pointer;
                                &:hover{
                                    background: rgb(173, 173, 173);
                                }
                                img{
                                    height:0.16rem;
                                    width:0.16rem;
                                    margin-right: 0.03rem;
                                }
                                p{
                                    font-size: 0.12rem;
                                    color:#4C4A4D;
                                    width: 0.9rem;
                                    text-overflow: ellipsis;  /* ellipsis:显示省略符号来代表被修剪的文本  string:使用给定的字符串来代表被修剪的文本*/
                                    white-space: nowrap;   /* nowrap:规定段落中的文本不进行换行   */
                                    overflow: hidden; /*超出部分隐藏*/
                                }
                            }
                        }
                    }

                }
                .table_con{
                    width:95%;
                    height:90%;
                    margin: 0 auto;
                    margin-top: 0.11rem;
                    position: relative;
                    overflow-y:auto;
                    &::-webkit-scrollbar {
                        width: 0.2rem;
                        height: 0.08rem;
                    }

                    &::-webkit-scrollbar-thumb {
                        border-radius: 0.12rem;
                        border: 0.06rem solid rgba(0, 0, 0, 0);
                        box-shadow: 0.08rem 0 0 #A5ADB7 inset;
                    }

                    &::-webkit-scrollbar-thumb:hover {
                        box-shadow: 0.08rem 0 0 #4A4A4A inset;
                    }
                    .zhishi_liebiao{
                        display: flex;
                        align-items: center;
                        position: absolute;
                        top:0.4rem;
                        left:0.85rem;
                        z-index: 2;
                        li{
                            font-size: 0.12rem;
                            color:#4C4A4D;
                            margin: 0 0.16rem;
                        }
                    }
                }
            }
        }
    }
    @media screen and (max-width: 1600px) {
        .quanping {
            width:1600px;
        }
    }
}
</style>
